import React, {useEffect, useState} from 'react'
import {useSelector} from 'react-redux';
import {Button, Checkbox, TextField, theme} from '@partssourceinc/react-ui-core';
import 'less/settings/companysettings.less';
import 'less/admin/adminsettings.less';
import styled from 'styled-components';
import {tccCustCareServices, titleMaxLength, subheaderMaxLength, phoneMaxLength} from 'data/TeamContactCenterData';
import {getFormattedPhoneNumber, stripAwayPhoneNumberSymbols,
    isValidEmail, isValidPhoneNumber} from 'utility';

const TeamContactCenterCustomerSupportContacts = ({customerCare, companyId, onConfirm, saving}) => {
    const [title, setTitle] = useState('');
    const [subheader, setSubheader] = useState('');
    const [phone, setPhone] = useState('');
    const [email, setEmail] = useState('');
    const [isValidEmailAddress, setIsValidEmailAddress] = useState(false);
    const [isActive, setIsActive] = useState(false);    
    const [isValidPhone, setIsValidPhone] = useState(false);
    const [showErrors, setShowErrors] = useState(false);
    const [servicesData, setServicesData] = useState(new Map());
    const permissions = useSelector(state => state.network.admin.permissions);
    let tabIndex = 1;
    
    const Description = styled.div`
        color: ${theme.grey3};
        margin-top: 5px;
    `;

    const Subtitle = styled.h3`
        color: #666;
        margin-top: 24px;
        font-weight: 600;
    `;

    useEffect(() => {
        if (customerCare) {
            setTitle(customerCare.title);
            setSubheader(customerCare.subheader);
            setPhone(customerCare.phone);
            setEmail(customerCare.email);
            setIsActive(customerCare.isActive);
            fillServicesData(customerCare.services);
        } else {
            fillServicesData();
        }
        
    }, [customerCare]);

    useEffect(() => {
        if (isValidPhoneNumber(phone)) {
            setIsValidPhone(true);
        } else {
            setIsValidPhone(false);
        }
    }, [phone, showErrors]);

    useEffect(() => {
        setIsValidEmailAddress(isValidEmail(email));

    }, [email, showErrors]);

    const fillServicesData = (services) => {
        let data = new Map();

        if (services) {
            const servicesIds = services.split(',');

            _.forEach(servicesIds, function (serviceId) {
                data.set(serviceId, true);
            });
            _.forEach(tccCustCareServices, function (service) {
                if (servicesIds.includes(service.id)) {
                    data.set(service.id, true);
                } else {
                    data.set(service.id, false);
                }
            });
        } else {
            _.forEach(tccCustCareServices, function (service) {
                data.set(service.id, false);
            });
        }

        const dataOrdered = new Map([...data.entries()].sort())
        setServicesData(dataOrdered);
    }
    const onSettingARChange = (e) => {
        setIsActive(e.checked);
    }
    const onServiceChange = (e, serviceId) => {
        const updatedServices = new Map(servicesData);
        updatedServices.set(serviceId, e.checked);
        setServicesData(updatedServices);
    }
    const renderCustCareServices = () => {
        return (
            <div>
                {servicesData && Array.from(servicesData).map(([key]) => renderCustCareCheckBox(key))}
            </div>
        );
    }
    const renderCustCareCheckBox = (serviceId) => {
        const tccCustCareService = tccCustCareServices.find(x => x.id === serviceId);

        return (
            <div className="section">
                <Checkbox
                    label={tccCustCareService.display}
                    checked={servicesData && servicesData.get(serviceId)}
                    onChange={e => onServiceChange(e, serviceId)}
                    key={serviceId}
                    tabIndex={tabIndex++}
                    className="checkbox"
                />
            </div>
        );
    }
    const saveCustomerCare = () => {
        let servicesString = Array.from(servicesData.entries())
            .filter(([_, value]) => value === true)
            .map(([key, _]) => key)
            .join(',');

        let data = {
            id: customerCare && customerCare.id ? customerCare.id : 0,
            companyId,
            subheader: subheader.trim(),
            title: title.trim(),
            phone: phone.trim(),
            email: email.trim(),
            services: servicesString,
            isActive: isActive,
            active: isActive ? 'Y' : 'N',
        }
        onConfirm(data);
    };
    const validateForm = () => {
        let isValid = true;

        if (!title || !subheader || !isValidPhone || !isValidEmailAddress) {
            isValid = false;
        }
        return isValid;
    }
    const onSave = () => {
        const isValid = validateForm();

        if (!isValid) {
            setShowErrors(true);
        } else {
            setShowErrors(false);
            saveCustomerCare();
        }
    }
    const onPhoneNumberChange = (e) => {
        setPhone(stripAwayPhoneNumberSymbols(e.target.value));
    };
    const onEmailChange = (e) => {
        setEmail(e.target.value);
    };

    return (
        <div className="tcc-additional-container">
            <div className="section" key="title">
                <div className="col-md-12">
                    <Checkbox
                        id="activeAR"
                        key={'chk' + Date.now().toString()}
                        label="Is Active"
                        checked={isActive}
                        onChange={onSettingARChange}
                        className="checkbox"
                        tabIndex={tabIndex++}
                    />
                </div>
                <div className="col-md-12">
                    <TextField
                        id="title"
                        name="title"
                        label="Title"
                        onChange={(e) => setTitle(e.target.value)}
                        text={title}
                        placeholder="Title"
                        className="text-field"
                        maxLength={titleMaxLength}
                        required={true}
                        showErrorMessage={showErrors && !title}
                        tabIndex={tabIndex++}
                    />
                    <Description>Example: Customer Care</Description>
                </div>
                <div className="col-md-12">
                    <TextField
                        id="subheader"
                        name="subheader"
                        label="Header"
                        onChange={(e) => setSubheader(e.target.value)}
                        text={subheader}
                        className="text-field"
                        multiLine={true}
                        maxLength={subheaderMaxLength}
                        required={true}
                        showErrorMessage={showErrors && !subheader}
                        tabIndex={tabIndex++}
                    />
                    <Description>Example: Have questions on your orders? Let us help you with any of your processing questions.</Description>
                </div>
                <div className="col-md-12">
                    <TextField
                        id="phone"
                        label="Phone Number"
                        onChange={onPhoneNumberChange}
                        text={getFormattedPhoneNumber(phone)}
                        className="text-field"
                        maxLength={phoneMaxLength}
                        required={true}
                        showErrorMessage={showErrors && !isValidPhone}
                        tabIndex={tabIndex++}
                    />
                </div>
                <div className="col-md-12">
                    <TextField
                        id="email"
                        label="Email Address"
                        onChange={onEmailChange}
                        text={email}
                        className="text-field"
                        maxLength={100}
                        required={true}
                        showErrorMessage={showErrors && !isValidEmailAddress}
                        tabIndex={tabIndex++}
                    />
                </div>
                <div className="col-md-12">
                    <Subtitle>Support Services</Subtitle>
                    {renderCustCareServices()}
                </div>
                {!permissions.readOnly && <div className="col-md-12 field">
                    <Button
                        onClick={onSave}
                        secondary={true}
                        loading={saving}
                        showConfirmationMsg={true}
                        tabIndex={tabIndex++}>
                        Save
                    </Button>
                </div>}
            </div>
        </div>
    );
}
export default TeamContactCenterCustomerSupportContacts;
