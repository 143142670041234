import React, {useEffect, useState} from 'react'
import {useSelector} from 'react-redux';
import {Button, Checkbox, TextField, theme} from '@partssourceinc/react-ui-core';
import 'less/settings/companysettings.less';
import 'less/admin/adminsettings.less';
import styled from 'styled-components';
import {isValidLink} from 'utility';
import {titleMaxLength, 
    subheaderMaxLength} from 'data/TeamContactCenterData';

const TeamContactCenterAdditional = ({additionalInfo, companyId, onConfirm, saving}) => {
    const [superheader, setSuperheader] = useState('');
    const [header, setHeader] = useState('');
    const [ctaLabel, setCtaLabel] = useState('');
    const [ctaLink, setCtaLink] = useState('');
    const [isValidCtaLink, setIsValidCtaLink] = useState(true);
    const [isActive, setIsActive] = useState(false);
    const [showFieldErrors, setShowFieldErrors] = useState(false);
    const permissions = useSelector(state => state.network.admin.permissions);

    useEffect(() => {
        if (additionalInfo) {
            setSuperheader(additionalInfo.title);
            setHeader(additionalInfo.subheader);
            setCtaLabel(additionalInfo.ctaLabel);
            setCtaLink(additionalInfo.ctaLink);
            setIsActive(additionalInfo.isActive);
        }
    }, [additionalInfo]);

    // TODO: validate the other fields
    useEffect(() => {
        if (isValidLink(ctaLink)) {
            setIsValidCtaLink(true);
        } else {
            setIsValidCtaLink(false);
        }
    }, [ctaLink, showFieldErrors]);

    const onSettingARChange = (e) => {
        setIsActive(e.checked);
    }
    const saveAdditional = () => {  
        let addResource = {
            id: additionalInfo && additionalInfo.id ? additionalInfo.id : 0,
            companyId,
            subheader: header,
            title: superheader,
            ctaLabel,
            ctaLink: ctaLink.trim(),
            isActive,
            Active: isActive ? 'Y' : 'N',
        }
        onConfirm(addResource);
    };     
   
    const Description = styled.div`
        color: ${theme.grey3};
        margin-top: 5px;
    `;

    const validateForm = () => {
        let isValid = true;

        if (!superheader || !header || !ctaLabel || !isValidCtaLink) {
            isValid = false;
        }
        return isValid;
    }

    const onSave = () => {
        const isValid = validateForm();

        if (!isValid) {
            setShowFieldErrors(true);
        } else {
            setShowFieldErrors(false);
            saveAdditional();
        }
    }

    return (
        <div className="tcc-additional-container">
            <div className="section" key="superheader">
                <div className="col-md-12">
                    <Checkbox id="activeAR" key={'chk' + Date.now().toString()} label="Is Active"
                        checked={isActive} onChange={onSettingARChange} className="checkbox" /> 
                </div>
                <div className="col-md-12">
                    <TextField
                        id="superheader"
                        name="superheader"
                        label="Superheader"
                        onChange={(e) => setSuperheader(e.target.value)}
                        text={superheader}
                        placeholder="Superheader (Optional)"                    
                        className="text-field"
                        maxLength={titleMaxLength}
                        required={true}
                        showErrorMessage={showFieldErrors && !superheader}
                    />
                    <Description>Example: Help Center</Description>
                </div>
                    
                <div className="col-md-12">
                    <TextField
                        id="header"
                        name="header"
                        label="Header"
                        onChange={(e) => setHeader(e.target.value)}
                        text={header}
                        className="text-field"
                        multiLine={true}
                        maxLength={subheaderMaxLength}
                        required={true}
                        showErrorMessage={showFieldErrors && !header}
                    />
                    <Description>Example: For all those other questions you may have, we are here, every step of the way.</Description>
                </div>
                <div className="col-md-12">   
                    <TextField
                        id="cta"
                        name="cta"
                        label="CTA"
                        onChange={(e) => setCtaLabel(e.target.value)}
                        text={ctaLabel}                    
                        className="text-field"
                        maxLength={25}
                        required={true}
                        showErrorMessage={showFieldErrors && !ctaLabel}
                    />
                    <Description>Example: See All Help Topics</Description>
                </div>
                <div className="col-md-12">   
                    <TextField
                        id="ctaUrl"
                        name="ctaUrl"
                        label="CTA URL"
                        onChange={(e) => setCtaLink(e.target.value)}
                        text={ctaLink}
                        className="text-field"
                        required={true}
                        showErrorMessage={showFieldErrors && !isValidCtaLink}
                    />
                    <Description>Example: https://www.domainplaceholder.com</Description>
                </div>
                {!permissions.readOnly && <div className="col-md-12 field">
                    <Button
                        onClick={onSave}
                        secondary={true} 
                        loading={saving}
                        showConfirmationMsg={true}>
                        Save
                    </Button>
                </div>}
            </div>
        </div>
    );
}
export default TeamContactCenterAdditional;
