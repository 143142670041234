import React, {useEffect, useState} from 'react'
import {useHistory} from 'react-router-dom';
import {useSelector} from 'react-redux';
import {CustomGrid, Button, Checkbox} from '@partssourceinc/react-ui-core';
import PopupAdmin from 'components/PopupAdmin';
import {tccServices} from 'data/TeamContactCenterData';
import axios from 'axios';
import styled from 'styled-components';
import TeamContactCenterAdditional from './TeamContactCenterAdditional';
import TeamContactCenterCustomerSupportContacts from './TeamContactCenterCustomerSupportContacts';
import {getFormattedPhoneNumber} from 'utility';
import 'less/settings/companysettings.less';
import 'less/admin/adminsettings.less';
import 'less/tabs.less';
import 'less/AdvancedLists/adminLists.less';
import 'less/settings/grideditor.less';
import 'less/settings/teamContactCenter.less';
const ToggleDiv = styled.div`
    margin-bottom: 10px;
    margin-left: 10px;
`;
export default function TeamContactCenter() {
    const tabKeys = [
        {
            id: 'AccountManagerTeam',
            fieldName: 'groupName',
            value: 'AccountManagerTeam',
            tabDisplay: 'Account Manager Team',
        },
        {
            id: 'CustomerSupportContacts',
            fieldName: 'groupName',
            value: 'CustomerSupportContacts',
            tabDisplay: 'Customer Support Contacts',
        },
        {
            id: 'AdditionalResources',
            fieldName: 'groupName',
            value: 'AdditionalResources',
            tabDisplay: 'Additional Resources',
        },
    ];
    const headerColumns = [
        {fieldName: 'title', displayName: 'Title', canSort: false},
        {fieldName: 'name', displayName: 'Name', canSort: false},
        {fieldName: 'phone', displayName: 'Phone', canSort: false},
        {fieldName: 'email', displayName: 'Email', canSort: false},
        {fieldName: 'services', displayName: 'Services', canSort: false},
        {fieldName: 'priority', displayName: 'Priority', canSort: false},
        {fieldName: 'active', displayName: 'Active', canSort: false},
    ];
    const history = useHistory();
    const facilityId = useSelector(state => state.settings.facility.facilityId);
    const [companyId, setCompanyId] = useState();
    const permissions = useSelector(state => state.network.admin.permissions);
    const [showRRT, setShowRRT] = useState(false);
    const [showAM, setShowAM] = useState(false);    
    const [currentTab, setCurrentTab] = useState(tabKeys[0]);
    const [selectedRow, setSelectedRow] = useState([]);
    const [dataAM, setDataAM] = useState([]);
    const [dataCC, setDataCC] = useState([]);
    const [dataAR, setDataAR] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [accountManagerSaving, setAccountManagerSaving] = useState(false);
    const [additionalSaving, setAdditionalSaving] = useState(false);
    const [supportSaving, setSupportSaving] = useState(false);
    const [showDeleteConfirmation,setShowDeleteConfirmation] = useState(false);

    useEffect(() => {
        if (permissions && !permissions.fullAdmin && !permissions.readOnly && !permissions.customerTeamContactCenterAdmin) 
            window.location.href = '/admin';
    }, []);

    useEffect(() => {
        if (facilityId)
            loadData();
    }, [facilityId]);

    const loadData = () => {
        setIsLoading(true);

        axios.get(`/ShoppingService/api/v1/company/teamcontact?companyId=${facilityId}`).then(x => {
            if (x.data)
                setCompanyId(x.data.companyId)
            if (x.data.teamCenterSettings) {
                setShowRRT(x.data.teamCenterSettings.displayRapidResponsePage === 'Y');
                setShowAM(x.data.teamCenterSettings.teamAccountManagersActive === 'Y');
            } else {
                setShowRRT(false);
                setShowAM(false);
            }
            if (x.data && x.data.teamAccountManagers && x.data.teamAccountManagers.length > 0) {
                x.data.teamAccountManagers.forEach(m => { 
                    if (m.services) {
                        let r = m.services.replaceAll(';',',');
                        let v = r.split(',');
                        v.join('').replace(/\s/gi,'').length === 0 ? m.services = null : v;
                    }
                });
            }

            setDataAM(x.data.teamAccountManagers);
            setDataCC(x.data.customerCare);
            setDataAR(x.data.additionalResource);
            setIsLoading(false);
        });
        
    };

    const onTabChange = (tab) => {
        setCurrentTab(tab);
    };
    const onSettingRRTChange = (e) => { 
        setShowRRT(e.checked);
    }
    const onSettingAMChange = (e) => { 
        setShowAM(e.checked);
    }
    const openAddAccountManager = () => {
        history.push({
            pathname: '/admin/teamcontactcenter/account-manager',
            state: {
                companyId: companyId,
            },
        });
    }
    const openEditManager = (item) => {
        setSelectedRow(item);
        history.push({
            pathname: '/admin/teamcontactcenter/account-manager',
            state: {
                companyId: companyId,
                accountManager: {
                    id: item.id,
                    personnelId: item.personnelId,
                    active: item.isActive,
                    title: item.title,
                    subheader: item.subheader,
                    accountName: item.name,
                    phone: item.phone,
                    email: item.email,
                    services: item.services,
                    priorityLevel: item.priority,
                },
            },
        });
    }
    const onConfirmDelete = (list) => {
        setSelectedRow(list);
        setShowDeleteConfirmation(true);
    }; 
    const onDeleteAM = () => {
        if (selectedRow && selectedRow.id) {
            setIsLoading(true);
            let managerId = selectedRow.id;
            axios.post(`/ShoppingService/api/v1/company/deleteTeamAccountManager/${managerId}`).then(() => {
                loadData();
                setShowDeleteConfirmation(false);
            });
            setIsLoading(false);
            setSelectedRow([]);
        }
    }
    const tableRows = dataAM.map(x =>
        (<tr key={`${x.id}-row`}>
            <td className="no-wrap">{x.title}</td>
            <td className="no-wrap">{x.name}</td>
            <td className="no-wrap">{getFormattedPhoneNumber(x.phone)}</td>
            <td className="no-wrap">{x.email}</td>
            <td>
                {!x.services ? <div></div> 
                    : x.services.split(',').length <= 3 ? convertToPrivacyPills(x.services).map(p => <div className="display-pill">{p.display}</div>) 
                        : <span>{ convertToPrivacyPills(x.services).slice(0, 3).map(p => <div className="display-pill">{`${p.display}`}</div>) } 
                            <span style={{fontWeight: '700', fontStyle: 'italic', color: '#949494'}}>+ more</span> </span> }
            </td>
            <td className="no-wrap">{x.priority}</td>
            <td className="no-wrap">{x.active}
                {!permissions.readOnly && <React.Fragment>
                    <i className="fa fa-trash-o edit" title="Delete Account Manager" onClick={() => onConfirmDelete(x)} />
                    <i className="fa fa-pencil edit" aria-hidden="true" title="Edit Account Manager" onClick={() => openEditManager(x)} />
                </React.Fragment>}
            </td>
        </tr>));

    function convertToPrivacyPills(list) {
        let pills = [];
        if (list && list.length > 0) { 
            let arr = list.split(',');
            arr.forEach(c => {
                if (c && c !== '') {
                    let i = tccServices.find(t => t.id === c);
                    pills.push({type: i.id, display: i.display.replace('&amp;','&')});
                }
            });  
        }  
        return pills;
    }

    function saveAdditionalResource (addResource) {
        setAdditionalSaving(true);
        axios.post(`/ShoppingService/api/v1/company/saveTeamContactCenterAdditional`, addResource).then(() => {
            setAdditionalSaving(false);
            setDataAR(addResource);
        });
    }

    function saveCustomerCare(addCustomerCare) {
        setSupportSaving(true);
        axios.post(`/ShoppingService/api/v1/company/saveTeamContactCenterCustomerCare`, addCustomerCare).then(() => {
            setSupportSaving(false);
            setDataCC(addCustomerCare);
        });
    }
    const saveManagers = () => {
        setAccountManagerSaving(true);
        setIsLoading(true);

        const request = {
            CompanyId: companyId,
            DisplayRapidResponsePage: (showRRT) ? 'Y' : 'N',
            TeamAccountManagersActive: (showAM) ? 'Y' : 'N',
        }

        axios.post(`/ShoppingService/api/v1/company/saveTeamContactCenterPage`, request).then (r => {
            loadData();
            setIsLoading(false);
            setAccountManagerSaving(false);
        });
    }
    return (
        <div className="admin-settings">
            <div>
                <h1>Team Contact Center Management</h1>
                <ToggleDiv>
                    <Checkbox id="enableRRT" key={'chk' + Date.now().toString()} label="Enable Team Contact Center Page"
                        checked={showRRT} onChange={onSettingRRTChange} className="checkbox" />
                </ToggleDiv>
                <div className="tab-wrapper" style={{minWidth: '1054px', marginBottom: '10px'}}>               
                    <>
                        {tabKeys.map((x, i) => (
                            <label className={currentTab.id === x.id ? 'checked' : ''} onClick={() => onTabChange(x)} key={i}>{x.tabDisplay}</label>
                        ))}                    
                    </>
                </div>
                {currentTab.id === 'AccountManagerTeam' && 
                    <React.Fragment>
                        <div className="adv-admin-lists">
                            <Checkbox id="activeAM" key={'chk' + Date.now().toString()} label="Is Active"
                                checked={showAM} onChange={onSettingAMChange} className="checkbox" />
                            {!permissions.readOnly && <div>
                                <div
                                    className={`add-item`}
                                    style={{'marginTop': '20px', 'display': 'inline-block'}}
                                    onClick={openAddAccountManager}>
                                    + Add Account Manager
                                </div>
                            </div>}
                            <CustomGrid
                                headerColumns={headerColumns}
                                totalRecords={(dataAM.length > 0) ? tableRows.length : 0}
                                showPagination={false}                            
                                defaultPageSize={100}
                                gridClassName="custom-grid">
                                {(dataAM.length > 0) ? tableRows : []}
                            </CustomGrid>
                            {!permissions.readOnly &&
                                <Button 
                                    onClick={saveManagers}
                                    secondary={true}
                                    loading={accountManagerSaving}
                                    showConfirmationMsg={true}
                                    type="submit">
                                    Save
                                </Button>}
                        </div>
                    </React.Fragment>
                }
                {currentTab.id === 'AdditionalResources' && !isLoading &&
                    <React.Fragment>
                        <div className="adv-admin-lists">
                            <TeamContactCenterAdditional
                                additionalInfo={dataAR}
                                companyId={companyId}
                                onConfirm={(addResource) => saveAdditionalResource(addResource)}
                                saving={additionalSaving}
                            />
                        </div>
                    </React.Fragment>
                }
                {currentTab.id === 'CustomerSupportContacts' && !isLoading &&
                    <React.Fragment>
                        <div className="adv-admin-lists">
                            <TeamContactCenterCustomerSupportContacts
                                customerCare={dataCC}
                                companyId={companyId}
                                onConfirm={(addCustomerCare) => saveCustomerCare(addCustomerCare)}
                                saving={supportSaving}
                            />
                        </div>
                    </React.Fragment>
                }
                {showDeleteConfirmation ?
                    <PopupAdmin confirmText="Yes"
                        cancelText="No"
                        show={true}
                        hideButtons={false}
                        onCancel={() => setShowDeleteConfirmation(false)}
                        onConfirm={onDeleteAM}
                        loading={isLoading}
                        className="confirm-item-delete-dialog"
                    >
                        <div style={{fontSize: '16px'}}>
                            Are you sure you want to delete this manager?
                        </div>
                    </PopupAdmin> : null
                }
            </div>
        </div>
    );
}
